<template>
  <div
    class="zy-charts-bar"
    :style="{ height: height }"
  >
    <div
      ref="charts"
      :style="{ height: height, width: width }"
    />
    <slot></slot>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      myChart: null,
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.updateCharts()
        })
      },
    },
  },
  props: {
    // 区块颜色顺序
    color: {
      type: Array,
      default: () => ["#66BBF9", "#FFB822", "#62FF85", "#007FF4"],
    },
    data: {
      type: Array,
      default: () => [],
    },
    //标题数组
    axis: {
      type: Array,
      default: () => [],
    },
    //柱状图方向，默认vertical为垂直，horizontal为横向
    direction: {
      type: String,
      default: "vertical",
    },
    height: {
      type: String,
      default: "214px",
    },
    width: {
      type: String,
      default: "100%",
    },
    defineTitle: {
      type: String,
      default: "",
    },
    defineLegend: {
      type: Array,
      default: () => [],
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    labelUnit: {
      type: String,
      default: "",
    },
  },
  computed: {
    option() {
      const option = {
        title: {
          text: this.defineTitle,
          textStyle: {
            color: "#ffffff",
            fontSize: "12px",
            fontWeight: "400",
          },
          top: 30,
        },
        tooltip: {
          show: true,
          trigger: "axis",
          borderWidth: 1,
          backgroundColor: "rgba(30,80,135,0.95)",
          borderColor: "#33D8FA",
          padding: [10, 10],
          confine: true,
          textStyle: {
            fontFamily: "SourceHanSansCN",
            fontWeight: 400,
            color: "#ffffff",
            fontSize: 14,
          },
          valueFormatter: (value) => value + this.labelUnit
        },
        xAxis: {
          type: this.direction == "vertical" ? "category" : "value",
          data: this.axis,
          splitLine: {
            show: this.direction != "vertical",
            lineStyle: {
              color: "#fff",
              type: "dashed", //虚线
              dashOffset: 12,
              opacity: 0.2,
            },
          },
          axisLabel: { show: true, textStyle: { fontSize: "12", color: "#B1B1B1" } },
        },
        yAxis: {
          type: this.direction != "vertical" ? "category" : "value",
          data: this.axis,
          splitLine: {
            show: this.direction == "vertical",
            lineStyle: {
              color: "#fff",
              type: "dashed", //虚线
              dashOffset: 12,
              opacity: 0.2,
            },
          },
          axisLabel: { 
            show: true, 
            textStyle: { fontSize: "13", color: "#ffffff" },
            formatter: '{value}' + this.labelUnit
           },
        },
        grid: {
          //设置边距
          left: "0px",
          right: this.direction == "vertical" ? "0px" : "20px",
          bottom: "10px",
          top: this.showTitle ? "60px" : "40px",
          containLabel: true,
        },
        legend: {
          //图示
          top: 0,
          left: 0,
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          textStyle: {
            fontSize: "14px",
            color: "#B1B1B1",
          },
        },
        color: this.color,
        series: [],
      }
      this.data.forEach((item) => {
        option.series.push({
          type: "bar",
          barWidth: "12px",
          animationDuration: 2500,
          ...item,
        })
      })
      if(this.defineLegend?.length > 0) option.legend = this.defineLegend
      return option
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initCharts()
    })
  },
  methods: {
    initCharts() {
      this.$nextTick(() => {
        if (!this.myChart) {
          var chartDom = this.$refs.charts
          var myChart = echarts.init(chartDom, null, {
            devicePixelRatio: window.devicePixelRatio || 1,
          })
          myChart.setOption(this.option)
          this.myChart = myChart
        }
      })
    },
    updateCharts() {
      this.myChart.setOption(this.option)
    },
    refreshEcharts() {
      this.option.series = []
      this.data.forEach((item) => {
        this.option.series.push({
          type: "bar",
          barWidth: "12px",
          ...item,
        })
      })
      this.myChart.setOption(this.option)
    },
  },
  beforeDestroy() {
    if (!this.myChart) {
      return
    }
    this.myChart.dispose()
    this.myChart = null
  },
}
</script>

<style lang="less" scoped>
.zy-charts-bar {
  position: relative;
  width: 100%;
  height: 100%;
  &-detail {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
